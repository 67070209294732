import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="bg-[#00A1A4] text-white text-3xl p-4 flex justify-center">
        <div className='w-56'>
          <img src="logo-beside-white.png" alt="Workflow" />
        </div>
      </header>
      <main>
        <div className='px-20 py-40 flex-col text-center'>
          <h2 className='text-[#00A1A4] text-xl font-bold block mb-4'>
            申し訳ありません！ただいまメンテナンス中です。
          </h2>
          <p className='block mb-4'>
            ただいまメンテナンス中のためご利用いただけません。<br />ご迷惑をおかけします。
          </p>
          <div className='py-4 px-6 border-[1px] border-gray-300 w-fit mx-auto mb-8'>
            <p>【メンテナンスの実施期間】</p>
            <p className='text-red-500'>11月15日(金) 21時 〜 11月18日(月) 9時まで</p>
          </div>
          <div className='flex justify-center'>
            <img className='text-center' src="yoii-footer.png" alt="Workflow" />
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
